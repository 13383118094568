import SiteWrap from "../components/layout/SiteWrap"

import React from "react"
import PageTitle from "../components/layout/PageTitle"
import InstagramSection from "../components/features/Instagram/Section"

export default () => (
  <SiteWrap>
    <PageTitle>Media</PageTitle>
    <InstagramSection />
  </SiteWrap>
)

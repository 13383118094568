import React from "react"
import TextContainer from "./TextContainer"
import styled from "@emotion/styled"
import { lighterShadow } from "../../styles/const"

const PageTitleComponent = styled.h1`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 10%;
    height: 0.1em;
    background: ${lighterShadow};
    left: 50%;
    bottom: -0.2em;
    transform: translateX(-50%);
  }
`

export default ({ children: pageTitle }) => (
  <TextContainer centerText={true}>
    <PageTitleComponent>{pageTitle}</PageTitleComponent>
  </TextContainer>
)
